var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import "regenerator-runtime/runtime";
import { CookiePreferenceBanner, populateCookiePreference, setCookiePreference, } from "@sezzle/shopper-auth-fe";
import WebFunnelClient from "@sezzle/web-product-events/funnel";
import { Dashboard, SHOPPER_COOKIE_CONSENT_BANNER, CookieBannerSteps, STATUS, } from "./interface";
import { optVWO } from "./injections";
const CookieConsentBannerFunnel = new WebFunnelClient(SHOPPER_COOKIE_CONSENT_BANNER, Dashboard, process.env.TARGET_ENV, process.env.REGION);
function getGeoIPDetails() {
    return __awaiter(this, void 0, void 0, function* () {
        const url = "https://geoip.sezzle.com/v1/geoip/ipdetails";
        try {
            const response = yield fetch(url);
            return yield response.json();
        }
        catch (err) {
            console.error(err);
            return null;
        }
    });
}
const handleViewed = () => {
    CookieConsentBannerFunnel.dispatchFunnelEvent({
        curStep: CookieBannerSteps.VIEWED.step,
        status: STATUS.SUCCESS,
        stepLevel: CookieBannerSteps.VIEWED.level,
    });
};
const handleAccept = () => {
    CookieConsentBannerFunnel.dispatchFunnelEvent({
        curStep: CookieBannerSteps.ACCEPTED.step,
        status: STATUS.SUCCESS,
        stepLevel: CookieBannerSteps.ACCEPTED.level,
    });
};
const handleDecline = () => {
    CookieConsentBannerFunnel.dispatchFunnelEvent({
        curStep: CookieBannerSteps.DECLINED.step,
        status: STATUS.SUCCESS,
        stepLevel: CookieBannerSteps.DECLINED.level,
    });
    optVWO(false);
};
const handleClose = (defaultAllowedAll) => {
    setCookiePreference({
        acted: true,
        allowedAll: defaultAllowedAll,
    });
    CookieConsentBannerFunnel.dispatchFunnelEvent({
        curStep: CookieBannerSteps.CLOSED.step,
        status: STATUS.SUCCESS,
        stepLevel: CookieBannerSteps.CLOSED.level,
    });
};
export const CookieConsentBanner = () => {
    const defaultAllowedRef = useRef(false);
    const [countryCode, setCountryCode] = useState("us");
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const geoIP = yield getGeoIPDetails();
            setCountryCode(geoIP.country_iso_code.toLowerCase());
            const cookiePreference = populateCookiePreference(geoIP);
            defaultAllowedRef.current = cookiePreference.allowedAll;
            optVWO(cookiePreference.allowedAll);
        }))();
    }, []);
    return (_jsx(_Fragment, { children: _jsx("div", { style: {
                position: "fixed",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1,
                maxWidth: "500px",
                width: "90%",
            }, children: _jsx(CookiePreferenceBanner, { locale: "en", onAccepted: handleAccept, onDeclined: handleDecline, onClickX: () => handleClose(defaultAllowedRef.current), onDisplayed: handleViewed, link: `https://legal.sezzle.com/privacy/en-${countryCode}` }) }) }));
};
