export const Dashboard = "dashboard";
export const SHOPPER_COOKIE_CONSENT_BANNER = "shopper_cookie_consent_banner";
export var STATUS;
(function (STATUS) {
    STATUS["SUCCESS"] = "success";
    STATUS["FAIL"] = "failure";
})(STATUS || (STATUS = {}));
export const CookieBannerSteps = {
    VIEWED: { step: "banner_viewed", level: 0 },
    CLOSED: { step: "banner_closed", level: 1 },
    ACCEPTED: { step: "banner_accepted", level: 1 },
    DECLINED: { step: "banner_declined", level: 1 },
};
